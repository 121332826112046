"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import Account from "@/shared/lib/client-sdk/models/account";
import Plan from "@/shared/lib/client-sdk/models/plan";
import OrderLineTax from "@/shared/lib/client-sdk/models/order-line-tax";
import OrderLineFee from "@/shared/lib/client-sdk/models/order-line-fee";
import Payment from "@/shared/lib/client-sdk/models/payment";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class PaymentInstruction extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "PaymentInstruction";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payment-instructions"
    );
  }

  getApiPathForCreate(forSingleRecord = false) {
    if (
      !Object.prototype.hasOwnProperty.call(this, "orderLineId") ||
      this.orderLineId.length != 32
    )
      console.error(
        "Cannot create a payment instruction without an order line id"
      );

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      this.orderLineId +
      "/payment-instructions";

    if (forSingleRecord) requestUrl += "/create-one";

    return requestUrl;
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payment-instructions/" +
      this.id
    );
  }

  getAdminPath() {
    return "/order-lines/" + this.orderLineId;
  }

  getClientPath() {
    return (
      "/" +
      this.masterAccountId +
      "/orders/" +
      this.orderId +
      "#participants:" +
      this.orderLineId
    );
  }

  get searchResultTitle() {
    return "Payable To: " + this.payableTo;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    var subtitle = [];

    if (this.forBenefitOf) {
      subtitle.push("For Benefit Of: " + this.forBenefitOf + "<br />");
    }

    subtitle.push(this.distributionTypeDisplayName);

    return subtitle;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it laods it in.
  get relationships() {
    return {
      account: Account,
      plan: Plan,
      federalTaxes: OrderLineTax,
      stateTaxes: OrderLineTax,
      orderLineFees: OrderLineFee,
      payments: Payment,
    };
  }

  getFeeDependencyData(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payment-instructions/" +
      this.id +
      "/fee-dependency-data";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  get requestorFee() {
    return this._getFeeByType("requestorFee");
  }

  get paymentMethodFee() {
    return this._getFeeByType("paymentMethodFee");
  }

  _getFeeByType(feeTypeCode) {
    if (!this.orderLineFees) {
      return null;
    }

    for (var i = 0; i < this.orderLineFees.length; i++) {
      if (this.orderLineFees[i].feeTypeCode == feeTypeCode) {
        return this.orderLineFees[i];
      }
    }

    return null;
  }

  get feesPaidByPayee() {
    if (!this.orderLineFees) {
      return [];
    }

    var totalFeesPaidByPayee = Math.addAmounts(
      this.totalFeesFromTaxableAmountPaidByPayee,
      this.totalFeesFromUntaxableAmountPaidByPayee
    );

    var feesPaidByPayee = [];
    for (var orderLineFee of this.orderLineFees) {
      if (orderLineFee.paidByCode == "payee") {
        totalFeesPaidByPayee -= orderLineFee.actualFeeAmount;
        feesPaidByPayee.push(orderLineFee);
      }
    }

    if (totalFeesPaidByPayee > 0) {
      feesPaidByPayee.push(
        new OrderLineFee({
          feeTypeCode: "otherFees",
          feeTypeDisplayName: "Other Fees",
          paidByCode: "payee",
          feeAmount: totalFeesPaidByPayee,
        })
      );
    }

    return feesPaidByPayee;
  }

  get calculatedTotalFeesNotPaidByPayee() {
    if (!this.orderLineFees) {
      return 0;
    }

    var total = 0;
    for (var orderLineFee of this.orderLineFees) {
      if (orderLineFee.paidByCode != "payee") {
        total += orderLineFee.actualFeeAmount;
      }
    }

    return total;
  }

  get calculatedGrossDistributionAmount() {
    return Math.addAmounts(this.grossTaxableAmount, this.grossUntaxableAmount);
  }

  get calculatedGrossTaxableAmountIncludingDefaultingLoan() {
    var loanAmount = this.federalTaxes
      ? this.federalTaxes.assessedAdditionalTaxableAmountForDefaultingLoan
      : 0;
    return Math.addAmounts(this.grossTaxableAmount, loanAmount);
  }

  get defaultingLoanAmount() {
    return this.federalTaxes
      ? this.federalTaxes.assessedAdditionalTaxableAmountForDefaultingLoan
      : 0;
  }

  get calculatedNetDistributionAmount() {
    return Math.subtractAmounts(
      this.calculatedGrossDistributionAmount,
      this.totalFeesPaidByPayee,
      this.federalTaxes ? this.federalTaxes.actualTaxAmount : 0,
      this.stateTaxes ? this.stateTaxes.actualTaxAmount : 0,
      this.localTaxes ? this.localTaxes.actualTaxAmount : 0
    );
  }

  get calculatedNetTaxableDistributionAmount() {
    return Math.subtractAmounts(
      this.grossTaxableAmount,
      this.totalFeesFromTaxableAmountPaidByPayee,

      this.federalTaxes ? this.federalTaxes.actualTaxAmount : 0,
      this.stateTaxes ? this.stateTaxes.actualTaxAmount : 0,
      this.localTaxes ? this.localTaxes.actualTaxAmount : 0
    );
  }

  get calculatedNetUntaxableDistributionAmount() {
    return Math.subtractAmounts(
      this.grossUntaxableAmount,
      this.totalFeesFromUntaxableAmountPaidByPayee
    );
  }

  static getPaymentInstructionFees(orderLineId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      orderLineId +
      "/payment-instruction-fees";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static getPaymentInstructionServiceOptions(orderLineId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines/" +
      orderLineId +
      "/payment-instruction-service-options";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  sendBack(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-instructions/" +
      this.id +
      "/send-back";

    ApiRequest.send("POST", requestUrl, null, PaymentInstruction).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  sendForReview(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-instructions/" +
      this.id +
      "/send-for-review";

    ApiRequest.send("POST", requestUrl, null, PaymentInstruction).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  sendPaymentInstructionBack(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-instructions/" +
      this.id +
      "/not-ready-for-review";

    ApiRequest.send("POST", requestUrl, null, PaymentInstruction).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  get isRolloverDistributionType() {
    return (
      (this.distributionTypeCode?.startsWith("rollover") &&
        this.distributionTypeCode != "rolloverToNextLevelIra" &&
        this.distributionTypeCode?.toLowerCase().indexOf("dmp") === -1) ||
      this.distributionTypeCode == "inPlanRothConversion"
    );
  }

  get isCashLumpSumDistributionType() {
    return (
      this.distributionTypeCode && this.distributionTypeCode == "cashLumpSum"
    );
  }

  get isNextlevelIraDistributionType() {
    return (
      this.distributionTypeCode &&
      this.distributionTypeCode == "rolloverToNextLevelIra"
    );
  }

  get isCheckPayment() {
    return this.paymentMethodCode && this.paymentMethodCode.startsWith("check");
  }

  get isAchPayment() {
    return this.paymentMethodCode == "achPayment";
  }

  get isWirePayment() {
    return this.paymentMethodCode == "wirePayment";
  }

  get isElectronicPayment() {
    return this.isAchPayment || this.isWirePayment;
  }

  get sentPayment() {
    var payments = this.getPaymentsByStatus("sent");

    return payments.length > 0 ? payments[0] : null;
  }

  getPaymentsByStatus(status) {
    if (!this.payments || this.payments.length == 0) {
      return [];
    }

    var filteredPayments = [];

    for (var payment of this.payments) {
      if (payment.status == status) {
        filteredPayments.push(payment);
      }
    }

    return filteredPayments;
  }

  static bulkApprovePaymentInstructions(paymentInstructions, callback) {
    PaymentInstruction.bulkStatement(
      "approve-payment-instructions",
      paymentInstructions,
      callback
    );
  }

  static bulkUnapprovePaymentInstructions(paymentInstructions, callback) {
    PaymentInstruction.bulkStatement(
      "unapprove-payment-instructions",
      paymentInstructions,
      callback
    );
  }

  static bulkPausePaymentInstructions(paymentInstructions, callback, note) {
    PaymentInstruction.bulkStatement(
      "pause-payment-instructions",
      paymentInstructions,
      callback,
      note
    );
  }

  static bulkUnpausePaymentInstructions(paymentInstructions, callback) {
    PaymentInstruction.bulkStatement(
      "unpause-payment-instructions",
      paymentInstructions,
      callback
    );
  }

  static bulkStatement(method, paymentInstructions, callback, note) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/" +
      method;

    var paymentInstructionIds = paymentInstructions.map((x) => x.id);
    var body = {
      paymentInstructionIds: paymentInstructionIds,
      note: note,
    };

    ApiRequest.send("POST", requestUrl, body, PaymentInstruction).onComplete(
      function (response) {
        if (response.successful) {
          for (var i = 0; i < response.result.length; i++) {
            var updatedPaymentInstruction = response.result[i];
            var existingPaymentInstruction = paymentInstructions.find(
              (x) => x.id == updatedPaymentInstruction.id
            );

            if (existingPaymentInstruction)
              updatedPaymentInstruction.copyAttributesTo(
                existingPaymentInstruction
              );
          }

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static createReliusAccount(requestBody, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/relius-add-person";

    ApiRequest.send(
      "POST",
      requestUrl,
      requestBody,
      PaymentInstruction
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static setReliusPreferences(requestBody, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/ijoin-set-relius-preferences";

    ApiRequest.send(
      "POST",
      requestUrl,
      requestBody,
      PaymentInstruction
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static setInitialReliusAccountBalance(requestBody, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/relius-set-balance";

    ApiRequest.send(
      "POST",
      requestUrl,
      requestBody,
      PaymentInstruction
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static checkSetInitialReliusAccountBalanceStatus(requestBody, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/relius-check-set-balance-job-status";

    ApiRequest.send(
      "POST",
      requestUrl,
      requestBody,
      PaymentInstruction
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default PaymentInstruction;
